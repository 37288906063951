// Wrap the code in a DOMContentLoaded event listener to ensure DOM is loaded
document.addEventListener('DOMContentLoaded', function() {
    // Previous scroll position
    let lastScrollTop = 0;

    // Navbar element
    const navbar = document.getElementById('navbar');

    // Check if navbar exists before proceeding
    if (navbar) {
        // Initial state
        navbar.style.position = 'fixed';
        navbar.style.width = '100%';
        navbar.style.top = '0';
        navbar.style.zIndex = '1000';

        // Add event listener for scroll
        window.addEventListener('scroll', function () {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // Add transparency based on scroll position
            if (scrollTop > 0) {
                navbar.classList.add('bg-white/90', 'backdrop-blur-sm');
            } else {
                navbar.classList.remove('bg-white/90', 'backdrop-blur-sm');
            }

            // Determine scroll direction
            if (scrollTop > lastScrollTop && scrollTop > 100) {
                // Scrolling down
                navbar.style.transform = 'translateY(-100%)';
            } else {
                // Scrolling up
                navbar.style.transform = 'translateY(0)';
            }

            lastScrollTop = scrollTop;
        });
    } else {
        console.warn('Navbar element not found');
    }
});
